@tailwind base;
@tailwind components;
@tailwind utilities;

.min-h-screen-minus-50px {
  min-height: calc(21vw);
}

:root {
  --bg-color: #ffffff; 
  --text-color: #000000;
  --hover-color: #b91c1c;  
}    

.theme-dark {
  --bg-color: #1a202c;  
  --text-color: #ffffff;
  --hover-color: #b91c1c
}

.theme-light {
  --bg-color: #f3f4f6;   
  --text-color: #000000;
  --hover-color: #b91c1c
}

/* Apply these variables in Tailwind */
.bg-theme {
  background-color: var(--bg-color);
}

.text-theme {
  color: var(--text-color);
}

/* *{
  @apply transition-color duration-200;
} */

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* index.css */


